import {
  ADD_INSTITUTION_SUBJECT,
  ADD_INSTITUTION_SUBJECT_FAIL,
  ADD_INSTITUTION_SUBJECT_SUCCESS,
  DELETE_INSTITUTION_SUBJECT,
  DELETE_INSTITUTION_SUBJECT_FAIL,
  DELETE_INSTITUTION_SUBJECT_SUCCESS
} from '../constants/institutions';

import {
  FETCH_MY_INSTITUTION,
  FETCH_MY_INSTITUTION_SUCCESS,
  FETCH_MY_INSTITUTION_FAIL,
  FETCH_MY_INSTITUTION_USERS,
  FETCH_MY_INSTITUTION_USERS_SUCCESS,
  FETCH_MY_INSTITUTION_USERS_FAIL,
  FETCH_MY_INSTITUTION_SUBJECTS,
  FETCH_MY_INSTITUTION_SUBJECTS_SUCCESS,
  FETCH_MY_INSTITUTION_SUBJECTS_FAIL,
  FETCH_MY_INSTITUTION_STATISTICS,
  FETCH_MY_INSTITUTION_STATISTICS_SUCCESS,
  FETCH_MY_INSTITUTION_STATISTICS_FAIL,
  UPDATE_MY_INSTITUTION_STATISTICS,
  UPDATE_MY_INSTITUTION_STATISTICS_SUCCESS,
  UPDATE_MY_INSTITUTION_STATISTICS_FAIL,
  FETCH_MY_INSTITUTION_SELF_EVALUATION,
  FETCH_MY_INSTITITUTION_SELF_EVALUATION_SUCCESS,
  FETCH_MY_INSTITITUTION_SELF_EVALUATION_FAIL,
  UPDATE_MY_INSTITUTION_SELF_EVALUATION,
  UPDATE_MY_INSTITUTION_SELF_EVALUATION_SUCCESS,
  UPDATE_MY_INSTITUTION_SELF_EVALUATION_FAIL,
  DELETE_MY_INSTITUTION_SELF_EVALUATION,
  DELETE_MY_INSTITUTION_SELF_EVALUATION_SUCCESS,
  DELETE_MY_INSTITUTION_SELF_EVALUATION_FAIL,
  SHOW_MY_INSTITUTION_SELF_EVALUATION_FORM,
  CLOSE_MY_INSTITUTION_SELF_EVALUATION_FORM,
  UPDATE_MY_INSTITUTION_SELF_EVALUATION_RESPONSE,
  UPDATE_MY_INSTITUTION_SELF_EVALUATION_RESPONSE_SUCCESS,
  UPDATE_MY_INSTITUTION_SELF_EVALUATION_RESPONSE_FAIL,
  FETCH_MY_INSTITUTION_CURRENT_RESPONSE,
  FETCH_MY_INSTITUTION_CURRENT_RESPONSE_SUCCESS,
  FETCH_MY_INSTITUTION_CURRENT_RESPONSE_FAIL,
  RESET_MY_INSTITUTION_ERRORS,
  IMyInstitutionState,
  MyInstitutionActionTypes
} from '../constants/myInstitution';

const initialState: IMyInstitutionState = {
  institution: null,
  users: [],
  subjects: [],
  statistics: [],
  selfEvaluation: [],
  currentResponse: [],
  fetchingMyInstitution: false,
  fetchingMyInstitutionUsers: false,
  fetchingMyInstitutionSubjects: false,
  fetchingMyInstitutionStatistics: false,
  fetchingMyInstitutionSelfEvaluation: false,
  fetchingMyInstitutionCurrentResponse: false,
  updatingMyInstitutionSubjects: false,
  updatingMyInstitutionStatistics: false,
  addingMyInstitutionSelfEvaluation: false,
  showMyInstitutionSelfEvaluationForm: false,
  deletingMyInstitutionSelfEvaluation: false,
  updatingMyInstitutionSelfEvaluationResponse: false,
  errors: null
};

const myInstitutionReducer = (
  state = initialState,
  action: MyInstitutionActionTypes
): IMyInstitutionState => {
  switch (action.type) {
    case FETCH_MY_INSTITUTION:
      return {
        ...state,
        fetchingMyInstitution: true
      };
    case FETCH_MY_INSTITUTION_SUCCESS:
      return {
        ...state,
        institution: action.payload.institution,
        errors: null,
        fetchingMyInstitution: false
      };
    case FETCH_MY_INSTITUTION_FAIL:
      return {
        ...state,
        institution: null,
        errors: action.payload.errors,
        fetchingMyInstitution: false
      };
    case FETCH_MY_INSTITUTION_USERS:
      return {
        ...state,
        fetchingMyInstitutionUsers: true
      };
    case FETCH_MY_INSTITUTION_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        errors: null,
        fetchingMyInstitutionUsers: false
      };
    case FETCH_MY_INSTITUTION_USERS_FAIL:
      return {
        ...state,
        users: [],
        errors: action.payload.errors,
        fetchingMyInstitutionUsers: false
      };
    case FETCH_MY_INSTITUTION_SUBJECTS:
      return {
        ...state,
        fetchingMyInstitutionSubjects: true
      };
    case FETCH_MY_INSTITUTION_SUBJECTS_SUCCESS:
      return {
        ...state,
        subjects: action.payload.subjects,
        errors: null,
        fetchingMyInstitutionSubjects: false
      };
    case FETCH_MY_INSTITUTION_SUBJECTS_FAIL:
      return {
        ...state,
        subjects: [],
        errors: action.payload.errors,
        fetchingMyInstitutionSubjects: false
      };
    case ADD_INSTITUTION_SUBJECT:
      return {
        ...state,
        updatingMyInstitutionSubjects: true
      };
    case ADD_INSTITUTION_SUBJECT_SUCCESS: {
      return {
        ...state,
        updatingMyInstitutionSubjects: false,
        subjects: action.payload.institutionSubjects,
        errors: null
      };
    }
    case ADD_INSTITUTION_SUBJECT_FAIL: {
      return {
        ...state,
        updatingMyInstitutionSubjects: false,
        errors: action.payload.errors
      };
    }
    case DELETE_INSTITUTION_SUBJECT:
      return {
        ...state,
        updatingMyInstitutionSubjects: true
      };
    case DELETE_INSTITUTION_SUBJECT_SUCCESS:
      return {
        ...state,
        updatingMyInstitutionSubjects: false,
        errors: null,
        subjects: state.subjects.filter(
          (subject) => subject.id !== action.payload.institutionSubjectId
        )
      };
    case DELETE_INSTITUTION_SUBJECT_FAIL:
      return {
        ...state,
        updatingMyInstitutionSubjects: false,
        errors: action.payload.errors
      };
    case FETCH_MY_INSTITUTION_STATISTICS:
      return {
        ...state,
        fetchingMyInstitutionStatistics: true
      };
    case FETCH_MY_INSTITUTION_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: action.payload.statistics,
        errors: null,
        fetchingMyInstitutionStatistics: false
      };
    case FETCH_MY_INSTITUTION_STATISTICS_FAIL:
      return {
        ...state,
        statistics: [],
        errors: action.payload.errors,
        fetchingMyInstitutionStatistics: false
      };
    case UPDATE_MY_INSTITUTION_STATISTICS:
      return {
        ...state,
        updatingMyInstitutionStatistics: true
      };
    case UPDATE_MY_INSTITUTION_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: state.statistics.map((statistic) =>
          statistic.evaluationPeriodId === action.payload.statistics.evaluationPeriodId
            ? action.payload.statistics
            : statistic
        ),
        errors: null,
        updatingMyInstitutionStatistics: false
      };
    case UPDATE_MY_INSTITUTION_STATISTICS_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        updatingMyInstitutionStatistics: false
      };
    case FETCH_MY_INSTITUTION_SELF_EVALUATION:
      return {
        ...state,
        fetchingMyInstitutionSelfEvaluation: true
      };
    case FETCH_MY_INSTITITUTION_SELF_EVALUATION_SUCCESS:
      return {
        ...state,
        selfEvaluation: action.payload.selfEvaluation,
        errors: null,
        fetchingMyInstitutionSelfEvaluation: false
      };
    case FETCH_MY_INSTITITUTION_SELF_EVALUATION_FAIL:
      return {
        ...state,
        selfEvaluation: [],
        errors: action.payload.errors,
        fetchingMyInstitutionSelfEvaluation: false
      };
    case UPDATE_MY_INSTITUTION_SELF_EVALUATION:
      return {
        ...state,
        addingMyInstitutionSelfEvaluation: true
      };
    case UPDATE_MY_INSTITUTION_SELF_EVALUATION_SUCCESS:
      return {
        ...state,
        selfEvaluation: [...state.selfEvaluation, action.payload.selfEvaluation],
        errors: null,
        addingMyInstitutionSelfEvaluation: false
      };
    case UPDATE_MY_INSTITUTION_SELF_EVALUATION_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        addingMyInstitutionSelfEvaluation: false
      };
    case SHOW_MY_INSTITUTION_SELF_EVALUATION_FORM:
      return {
        ...state,
        errors: null,
        showMyInstitutionSelfEvaluationForm: true
      };
    case CLOSE_MY_INSTITUTION_SELF_EVALUATION_FORM:
      return {
        ...state,
        errors: null,
        showMyInstitutionSelfEvaluationForm: false
      };
    case DELETE_MY_INSTITUTION_SELF_EVALUATION:
      return {
        ...state,
        deletingMyInstitutionSelfEvaluation: true
      };
    case DELETE_MY_INSTITUTION_SELF_EVALUATION_SUCCESS:
      return {
        ...state,
        selfEvaluation: state.selfEvaluation.filter((item) => item.id !== action.payload.id),
        errors: null,
        deletingMyInstitutionSelfEvaluation: false
      };
    case DELETE_MY_INSTITUTION_SELF_EVALUATION_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        deletingMyInstitutionSelfEvaluation: false
      };
    case UPDATE_MY_INSTITUTION_SELF_EVALUATION_RESPONSE:
      return {
        ...state,
        updatingMyInstitutionSelfEvaluationResponse: true
      };
    case UPDATE_MY_INSTITUTION_SELF_EVALUATION_RESPONSE_SUCCESS:
      return {
        ...state,
        currentResponse: action.payload.currentResponse,
        errors: null,
        updatingMyInstitutionSelfEvaluationResponse: false
      };
    case UPDATE_MY_INSTITUTION_SELF_EVALUATION_RESPONSE_FAIL:
      return {
        ...state,
        currentResponse: [],
        errors: action.payload.errors,
        updatingMyInstitutionSelfEvaluationResponse: false
      };
    case FETCH_MY_INSTITUTION_CURRENT_RESPONSE:
      return {
        ...state,
        fetchingMyInstitutionCurrentResponse: true
      };
    case FETCH_MY_INSTITUTION_CURRENT_RESPONSE_SUCCESS:
      return {
        ...state,
        currentResponse: action.payload.currentResponse,
        errors: null,
        fetchingMyInstitutionCurrentResponse: false
      };
    case FETCH_MY_INSTITUTION_CURRENT_RESPONSE_FAIL:
      return {
        ...state,
        currentResponse: [],
        errors: action.payload.errors,
        fetchingMyInstitutionCurrentResponse: false
      };
    case RESET_MY_INSTITUTION_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default myInstitutionReducer;
